/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

import { Images } from "../../assets";
import { AnimatedPhones, FadeIn } from "../../components";
import { Colors } from "../../constants";

export const Main = (): JSX.Element => {
  return <ContentWrapper />;
};

const ContentWrapper = () => {
  return (
    <Box>
      <Nav />
      <Box>
        <Box>
          <Content />
        </Box>
      </Box>
    </Box>
  );
};

const GetAppButton = (
  <Button color={Colors.white} bg={Colors.primary} colorScheme={Colors.primary}>
    <a
      href="https://mongaz.page.link/u"
      rel="noreferrer"
      target="_blank"
      referrerPolicy="no-referrer"
    >
      {"Télécharger l'application"}
    </a>
  </Button>
);

function Nav() {
  return (
    <Flex
      alignItems={"center"}
      position={"fixed"}
      zIndex={10}
      height={"70px"}
      top={0}
      width={"100%"}
      boxShadow={"0 0 8px 0 rgba(255,255,255,0.8)"}
      bg={"white"}
    >
      <Flex
        width={"100%"}
        pl={4}
        pr={4}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Flex
          justifyItems={"center"}
          flexDirection={"row"}
          alignItems={"center"}
        >
          <Flex alignItems={"center"}>
            <Image
              style={{
                width: "100px",
                maxWidth: "100px !important",
              }}
              src={Images.logoTransparent}
              alt=""
            />
          </Flex>

          <Text
            sx={{
              "@media (max-width: 768px)": {
                display: "none",
              },
            }}
            fontWeight={"600"}
            fontSize={"2xl"}
            color={Colors.background}
          >
            MonGaz
          </Text>
        </Flex>
        <Flex>{GetAppButton}</Flex>
      </Flex>
    </Flex>
  );
}

function Content() {
  return (
    <>
      <R1 />
      <R2 />
      <R3 />
      <R4 />
      <R5 />
    </>
  );
}

function R1() {
  return (
    <Flex
      mt={100}
      justifyContent={"space-between"}
      sx={{
        "@media (max-width: 768px)": {
          flexDirection: "column",
        },
      }}
      alignItems={"center"}
    >
      <Flex p={4} flexDirection={"column"} alignItems={"end"}>
        <Text fontSize={"3xl"} color={"white"}>
          Le futur de la livraison de gaz à domicile
        </Text>
        <Text fontSize={"2xl"} color={"white"}>
          Créez votre magasin et commencez à recevoir des commandes.
        </Text>
      </Flex>

      <Flex alignItems={"center"}>
        <AnimatedPhones />
      </Flex>
    </Flex>
  );
}

function R2() {
  return (
    <Flex
      p={4}
      bg={Colors.background}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        "@media (max-width: 768px)": {
          flexDirection: "column",
        },
      }}
    >
      <Box>
        <FadeIn>
          <AnimatedPhones
            image={<Image width={350} src={Images.one} alt="" />}
          />
        </FadeIn>
      </Box>
      <Flex>
        <FadeIn>
          <Box
            sx={{
              "@media (min-width: 1024px)": {
                width: "95%",
              },
            }}
          >
            <Text
              color={"white"}
              sx={{
                "@media (max-width: 768px)": {
                  fontSize: "3xl",
                },
              }}
              fontSize={"7xl"}
            >
              {"S'inscrire gratuitement"}
            </Text>
            <Text pl={2} color={"white"} fontSize={"2xl"}>
              {
                "L'inscription est gratuite et votre premier magasin est offert si vous êtes un marchand de gaz."
              }
            </Text>
            <Text pl={5} color={"white"} fontSize={"2xl"}>
              <ul>
                <Text as="li" fontSize={"lg"}>
                  {"Trouver le magasin le plus proche"}
                </Text>
                <Text as="li" fontSize={"lg"}>
                  Passer une commande.
                </Text>
                <Text as="li" fontSize={"lg"}>
                  {"Recevoir des notifications sur votre commande"}.
                </Text>
              </ul>
            </Text>
          </Box>
        </FadeIn>
      </Flex>
    </Flex>
  );
}

function R3() {
  return (
    <Flex
      p={4}
      bg={Colors.background}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        "@media (max-width: 768px)": {
          flexDirection: "column",
        },
      }}
    >
      <Flex>
        <FadeIn>
          <Box
            sx={{
              "@media (min-width: 1024px)": {
                width: "80%",
              },
            }}
          >
            <Box>
              <Text
                sx={{
                  "@media (max-width: 768px)": {
                    fontSize: "3xl",
                  },
                }}
                color={"white"}
                fontSize={"7xl"}
              >
                Accepter ou refuser des commandes
              </Text>
            </Box>
            <Text color={"white"} fontSize={"lg"}>
              {
                "Vous pouvez décider d'accepter ou de refuser une commande si elle ne vous convient pas et le client en sera informé."
              }
            </Text>
          </Box>
        </FadeIn>
      </Flex>
      <Box>
        <FadeIn>
          <AnimatedPhones
            image={<Image width={350} src={Images.three} alt="" />}
          />
        </FadeIn>
      </Box>
    </Flex>
  );
}

function R4() {
  return (
    <Flex
      p={4}
      bg={Colors.background}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        "@media (max-width: 768px)": {
          flexDirection: "column",
        },
      }}
    >
      <Flex>
        <FadeIn>
          <AnimatedPhones
            image={<Image width={350} src={Images.six} alt="" />}
          />
        </FadeIn>
      </Flex>
      <Flex>
        <FadeIn>
          <Box>
            <Text
              sx={{
                "@media (max-width: 768px)": {
                  fontSize: "3xl",
                },
              }}
              color={"white"}
              fontSize={"7xl"}
            >
              Gérer vos commandes
            </Text>
            <Text pl={2} color={"white"} fontSize={"lg"}>
              En tant que client :
            </Text>
            <Text pl={6} color={"white"} fontSize={"lg"}>
              <ul>
                <Text as="li" fontSize={"lg"}>
                  {
                    "Vous pouvez annuler une commande avant qu'elle ne soit acceptée"
                  }
                </Text>
                <Text as="li" fontSize={"lg"}>
                  Une fois acceptée, une commande ne peut être annulée, mais
                  vous pouvez contacter le vendeur.
                </Text>
                <Text as="li" fontSize={"lg"}>
                  {
                    "Chaque commande de livraison doit être assortie d'un code de livraison pour que la transaction soit complète"
                  }
                  .
                </Text>
              </ul>
            </Text>
          </Box>
        </FadeIn>
      </Flex>
    </Flex>
  );
}

function R5() {
  return (
    <Flex
      p={4}
      bg={Colors.background}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        "@media (max-width: 768px)": {
          flexDirection: "column",
        },
      }}
    >
      <Flex>
        <FadeIn>
          <Flex flexDirection={"column"}>
            <Flex flexDirection={"column"}>
              <Text
                color={"white"}
                sx={{
                  "@media (max-width: 768px)": {
                    fontSize: "3xl",
                  },
                }}
                fontSize={"7xl"}
              >
                Gérer votre magasin
              </Text>
              <Box color={"white"} fontSize={"lg"}>
                <Text color={"white"} fontSize={"lg"}>
                  Faire le plein dans votre magasin
                </Text>
                <Text fontSize={"lg"}>
                  Suivez vos commandes en un seul endroit
                </Text>
              </Box>
            </Flex>

            <Box>{GetAppButton}</Box>
          </Flex>
        </FadeIn>
      </Flex>
      <Box>
        <FadeIn>
          <AnimatedPhones
            image={<Image width={350} src={Images.five} alt="" />}
          />
        </FadeIn>
      </Box>
    </Flex>
  );
}
