// import './App.css';
import "./Home.module.css";
import "./globals.css";

import { Box, ChakraProvider, Flex, Link } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { RouterProvider } from "react-router-dom";

import { Colors } from "./constants";
import { router } from "./router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * 60,
      cacheTime: 60,
    },
  },
});

const App = (): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <Flex
          width={"100%"}
          height={"100vh"}
          bg={Colors.background}
          flexDirection={"column"}
        >
          <RouterProvider router={router} />
          <Flex
            position={"fixed"}
            bottom={0}
            width={"100%"}
            flex={1}
            margin={"0 auto"}
            bg={"#F4F6F820"}
            // bg={"#F4F6F8"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Flex
              alignItems={"center"}
              flexDirection={"column"}
              position={"sticky"}
              bottom={0}
            >
              <Box
                style={{
                  fontSize: 16,
                  color: "white",
                }}
              >
                <b>Téléphone</b> :{" "}
                <a href="tel: +225 0142642302">+225 0142642302</a> <br />
                <b>Courriel</b> :{" "}
                <a href="mailto: support@mongaz.net">support@mongaz.net</a>
              </Box>
              <Link mt={2} color={"white"} href="/tandc">
                Politique de confidentialité
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default App;
