import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const Invite = (): JSX.Element => {
  const { id } = useParams();
  useEffect(() => {
    if (id !== undefined) {
      window.location.href = `https://mongaz.page.link/u?` + id;
    }
  }, [id]);

  return (
    <Flex
      flex={1}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Text color={"white"} textAlign={"center"} fontSize={"2xl"}>
        {id ? "Un instant, s'il vous plaît" : "Mauvaise adresse URL"}
      </Text>
    </Flex>
  );
};
