import { motion } from "framer-motion";
import React, { memo } from "react";
import { useInView } from "react-intersection-observer";

interface FadeInProps extends React.ComponentProps<typeof motion.div> {
  children: React.ReactNode;
}

export const FadeIn = memo<FadeInProps>((props: object) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 20 }}
      transition={{ duration: 1 }}
      {...props}
    />
  );
});
