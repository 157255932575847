export const Images = {
  logoTransparent: require("./logo-transparent.png"),
  one: require("./1.png"),
  two: require("./2.png"),
  three: require("./3.png"),
  four: require("./4.png"),
  five: require("./5.png"),
  six: require("./6.png"),
  seven: require("./7.png"),
};
