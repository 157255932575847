import { Box } from "@chakra-ui/react";
import React from "react";

export const TandC = (): JSX.Element => {
  return (
    <Box
      bg={"white"}
      style={{
        padding: 20,
        color: "black",
      }}
    >
      <h1>Politique de confidentialité de MonGaz</h1>
      <p>
        MonGaz (« nous », « notre », « nos ») est un service de livraison de gaz
        accessible via notre application mobile (« l&apos;application »). La
        confidentialité et la sécurité de vos informations personnelles sont
        importantes pour nous. Cette politique de confidentialité explique
        comment nous collectons, utilisons et partageons vos informations
        personnelles lorsque vous utilisez notre application. Veuillez lire
        attentivement cette politique de confidentialité avant d&apos;utiliser
        notre application.
      </p>
      <h2>Collecte d&apos;informations</h2>
      <p>
        Nous collectons les informations suivantes lorsque vous utilisez notre
        application :
      </p>
      <ul>
        <li>
          Informations de compte : lorsque vous vous inscrivez pour utiliser
          notre application, nous collectons votre nom, votre adresse e-mail,
          votre numéro de téléphone et votre adresse de livraison. Nous pouvons
          également collecter des informations de paiement si vous choisissez de
          commander et de payer en ligne.
        </li>
        <li>
          Données d&apos;utilisation : nous collectons des informations sur
          votre utilisation de l&apos;application, y compris les produits que
          vous commandez, la date et l&apos;heure de la commande, votre
          emplacement géographique et l&apos;appareil que vous utilisez pour
          accéder à l&apos;application.
        </li>
      </ul>
      <h2>Utilisation des informations</h2>
      <p>
        Nous utilisons vos informations personnelles pour les finalités
        suivantes :
      </p>
      <ul>
        <li>
          Fournir et améliorer notre service : nous utilisons vos informations
          personnelles pour traiter vos commandes, livrer les produits que vous
          avez commandés et améliorer l&apos;expérience utilisateur de notre
          application.
        </li>
        <li>
          Marketing et publicité : avec votre consentement, nous pouvons
          utiliser vos informations personnelles pour vous envoyer des
          informations sur nos produits et services, ainsi que pour vous montrer
          des publicités personnalisées.
        </li>
        <li>
          Conformité légale : nous pouvons utiliser vos informations
          personnelles pour nous conformer aux exigences légales et
          réglementaires applicables, telles que la lutte contre la fraude ou la
          protection de la sécurité nationale.
        </li>
      </ul>
      <h2>Partage des informations</h2>
      <p>
        MonGaz ne vend ni ne loue les informations personnelles des utilisateurs
        à des tiers. MonGaz peut partager les informations personnelles des
        utilisateurs avec des tiers, tels que des fournisseurs de services de
        paiement, des transporteurs ou des partenaires commerciaux, pour fournir
        un service complet aux utilisateurs. MonGaz peut également divulguer des
        informations personnelles si cela est requis par la loi ou si cela est
        nécessaire pour protéger les intérêts de MonGaz ou des utilisateurs.
      </p>

      <h2>Sécurité des informations</h2>
      <p>
        MonGaz prend des mesures raisonnables pour protéger les informations
        personnelles des utilisateurs contre l&apos;accès non autorisé, la
        divulgation, la modification ou la destruction. MonGaz utilise des
        méthodes de cryptage et de stockage sécurisées pour protéger les
        informations personnelles des utilisateurs.
      </p>

      <h2>Modification de la politique de confidentialité</h2>
      <p>
        MonGaz se réserve le droit de modifier cette politique de
        confidentialité à tout moment. Les utilisateurs seront informés des
        changements via l&apos;application ou par e-mail.
      </p>

      <h2>Contact</h2>
      <p>
        Si vous avez des questions ou des préoccupations concernant la politique
        de confidentialité de MonGaz, veuillez contacter notre service clientèle
        à l&apos;adresse suivante : <b>support@mongaz.net</b>.
      </p>
    </Box>
  );
};
