/* eslint-disable @typescript-eslint/naming-convention */
import { createBrowserRouter } from "react-router-dom";

import { Invite, Main, TandC } from "./screens";

export const router = createBrowserRouter(
  [
    {
      path: "/",
      Component: Main,
    },
    {
      path: "/tandc",
      Component: TandC,
    },
    {
      path: "/invite/:id?",
      Component: Invite,
    },
  ],
  {
    basename: "/",
  }
);
