import { Flex, Image } from "@chakra-ui/react";
import { motion, useAnimationControls } from "framer-motion";
import React, { memo, useEffect } from "react";

import { Images } from "../assets";

const boxVariants = (direction: number) => ({
  initial: { scale: 0, y: -100, x: 200 },
  animate: {
    scale: [0, 1.1, 1],
    y: 0,
    x: 0,
    rotate: 0,
    transition: {
      staggerChildren: 0.5,
      ease: "easeInOut",
      duration: 1,
    },
  },
  floating: {
    y: [0, direction * 30, 20, 0],
    rotate: [0, direction * 10, 0],
    transition: {
      ease: "easeInOut",
      duration: 50,
      repeat: Infinity,
    },
  },
});

interface AnimatedPhonesProps {
  image?: React.ReactNode;
  direction?: number;
}

export const AnimatedPhones = memo<AnimatedPhonesProps>(
  ({ image, direction = 1 }) => {
    const controls = useAnimationControls();

    useEffect(() => {
      controls.start("animate").then(() => {
        controls.start("floating");
      });

      return controls.stop;
    }, [controls]);

    if (image) {
      return (
        <motion.div animate={controls} initial="initial">
          <motion.div variants={boxVariants(direction)}>{image}</motion.div>
        </motion.div>
      );
    }

    return (
      <Flex>
        <motion.div animate={controls} initial="initial">
          <motion.div variants={boxVariants(1)}>
            <Image width={350} src={Images.two} alt="" />
          </motion.div>
        </motion.div>
        <motion.div animate={controls} initial="initial">
          <motion.div variants={boxVariants(-1)}>
            <Image width={350} src={Images.one} alt="" />
          </motion.div>
        </motion.div>
      </Flex>
    );
  }
);
